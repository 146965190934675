.dashboard-header {
  background-color: var(--primary-color);
  color: white;
  width: 100%;
  height: 60px;
  /* border-bottom: 1px solid #2d3748; */
  position: relative;
}

.dashboard-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  height: 100%;
  max-width: var(--max-width);
  margin: 0 auto;
}

.dashboard-header-left {
  display: flex;
  align-items: center;
}

.dashboard-header-right {
  display: flex;
  align-items: center;
}

.dashboard-logo {
  z-index: 10;
  display: flex;
}

.dashboard-logo img {
  height: 60px;
  width: 60px;
}
/* Mobile menu container */
.dashboard-mobile-menu-container {
  display: none;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

/* Mobile menu button */
.dashboard-mobile-menu-button {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  width: 30px;
  height: 24px;
  position: relative;
  z-index: 10;
}

.dashboard-mobile-menu-icon,
.dashboard-mobile-menu-icon::before,
.dashboard-mobile-menu-icon::after {
  position: absolute;
  width: 24px;
  height: 2px;
  background-color: white;
  transition: all 0.3s ease;
}

.dashboard-mobile-menu-icon {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.dashboard-mobile-menu-icon::before,
.dashboard-mobile-menu-icon::after {
  content: "";
  left: 0;
}

.dashboard-mobile-menu-icon::before {
  top: -8px;
}

.dashboard-mobile-menu-icon::after {
  bottom: -8px;
}

/* Navigation styles */
.dashboard-nav {
  display: flex;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.dashboard-nav ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.dashboard-nav-item {
  margin: 0 10px;
}

.dashboard-nav-item a {
  color: #cbd5e0;
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  padding: 8px 0;
  transition: color 0.2s ease;
}

.dashboard-nav-item a:hover {
  color: white;
}

.dashboard-notifications {
  margin-right: 20px;
}

.dashboard-notification-button {
  background: none;
  border: none;
  cursor: pointer;
  position: relative;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: background-color 0.2s ease;
}

.dashboard-notification-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.dashboard-profile-container {
  position: relative;
}

.dashboard-profile-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  transition: opacity 0.2s ease;
}

.dashboard-profile-button:hover {
  opacity: 0.8;
}

.dashboard-profile-image {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #4c5fe9;
}

.dashboard-profile-dropdown {
  position: absolute;
  top: calc(100% + 10px);
  right: 0;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  width: 200px;
  z-index: 100;
  overflow: hidden;
}

.dashboard-profile-dropdown ul {
  list-style: none;
  margin: 0;
  padding: 8px 0;
}

.dashboard-profile-dropdown li {
  padding: 0;
}

.dashboard-profile-dropdown li a {
  display: block;
  padding: 10px 16px;
  color: #1a202c;
  text-decoration: none;
  font-size: 14px;
  transition: background-color 0.2s ease;
}

.dashboard-profile-dropdown li a:hover {
  background-color: #f7fafc;
}

.dashboard-profile-dropdown .divider {
  height: 1px;
  background-color: #e2e8f0;
  margin: 8px 0;
}

/* Active state for navigation */
.dashboard-nav-item a.active {
  color: white;
  border-bottom: 2px solid #4c5fe9;
}

/* Media queries for responsive layout */
@media (max-width: 768px) {
  .dashboard-mobile-menu-container {
    display: block;
  }

  .dashboard-mobile-menu-button {
    display: block;
  }

  .dashboard-nav {
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: #1e293b;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transform: translateY(-150%);
    transition: transform 0.3s ease;
    z-index: 5;
  }

  .dashboard-nav.open {
    transform: translateY(0);
  }

  .dashboard-nav ul {
    flex-direction: column;
    width: 100%;
    padding: 16px;
  }

  .dashboard-nav-item {
    margin: 0;
    border-bottom: 1px solid #2d3748;
  }

  .dashboard-nav-item:last-child {
    border-bottom: none;
  }

  .dashboard-nav-item a {
    display: block;
    padding: 12px 0;
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .dashboard-header-container {
    padding: 0 12px;
  }

  .dashboard-notifications {
    margin-right: 12px;
  }

  .dashboard-profile-dropdown {
    width: 180px;
    right: -12px;
  }
}
