/* Make the sidebar scrollable */
.sidebar {
  height: 100vh; /* Full viewport height */
  display: flex;
  flex-direction: column;
}

.sidebar-header {
  flex-shrink: 0; /* Don't allow header to shrink */
  padding: 15px;
  border-bottom: 1px solid #e0e0e0;
}

.file-thumbnails {
  flex-grow: 1; /* Take remaining space */
  overflow-y: auto; /* Enable vertical scrolling */
  padding: 10px;
  scrollbar-width: thin; /* For Firefox */
}

/* Styling for Webkit browsers (Chrome, Safari, Edge) */
.file-thumbnails::-webkit-scrollbar {
  width: 6px;
}

.file-thumbnails::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.file-thumbnails::-webkit-scrollbar-thumb {
  background: #cdcdcd;
  border-radius: 3px;
}

.file-thumbnails::-webkit-scrollbar-thumb:hover {
  background: #b0b0b0;
}

/* Set consistent size for thumbnails */
.file-thumbnail {
  margin-bottom: 12px;
  min-height: 70px; /* Ensure consistent height */
}

.thumbnail-icon-container {
  width: 48px;
  height: 48px;
  position: relative; /* For the page count badge */
}
/* Add these CSS styles to your SmartLinkViewer.css file */

/* Smaller sidebar/minimap */
.sidebar.open {
  width: 180px; /* Reduced from 220px */
}

/* Update sidebar toggle button positioning */
.sidebar-toggle {
  left: 180px; /* Match with sidebar width */
}

/* Make thumbnail items smaller */
.file-thumbnail {
  margin-bottom: 12px; /* Reduced spacing */
}

.thumbnail-icon-container {
  width: 48px; /* Smaller icons */
  height: 48px;
}

.thumbnail-icon {
  font-size: 20px; /* Smaller icon font */
}

.thumbnail-filename {
  font-size: 12px; /* Smaller font for filenames */
  max-width: 130px; /* Narrower text area */
  /* display: none; */
  overflow: scroll;
}

.page-count-badge {
  font-size: 10px;
  padding: 1px 4px;
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  border-top-left-radius: 3px;
  line-height: 1;
}

.page-count {
  display: none;
}
/* PDF viewer header styles (similar to Word and PowerPoint) */
.pdf-viewer-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  background-color: #3498db;
  border-bottom: 1px solid #e9ecef;
}

.pdf-viewer-header h3 {
  margin: 0;
  font-size: 12px;
  font-weight: 500;
  color: #333;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: white;
}

/* Document and presentation navigation info */
/* here */
.doc-navigation,
.ppt-navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 0;
  background: #f5f5f5;
  border-bottom: 1px solid #ddd;
  font-size: 13px;
  color: #555;
  display: none;
}

.doc-navigation {
  display: none;
}
.slide-count,
.page-count {
  font-weight: 500;
}

/* PowerPoint fallback view styles */
.ppt-download-prompt {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 500px;
  margin: 2rem auto;
}

.ppt-icon {
  font-size: 4rem;
  color: #f39c12;
  margin-bottom: 1rem;
}

.ppt-download-prompt h3 {
  margin-bottom: 1rem;
  word-break: break-word;
}

.ppt-download-prompt .download-button {
  margin-top: 1.5rem;
  padding: 0.75rem 1.5rem;
  display: none !important;
}

/* Adjust main content area width */
.main-content {
  width: calc(
    100% - 180px
  ) !important; /* Force the smaller width when sidebar is open */
}

/* Refined download button styles */
.download-button {
  display: inline-flex;
  align-items: center;
  gap: 5px;
  padding: 6px 12px;
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  color: #495057;
  text-decoration: none;
  font-size: 14px;
  transition: all 0.2s ease;
}

.download-button:hover {
  background-color: #e9ecef;
  border-color: #ced4da;
  color: #212529;
}

.download-button svg {
  font-size: 14px;
}

/* Adjust PDF controls to work with the header */
.pdf-controls {
  padding: 8px 15px;
  background-color: #f8f9fa;
  border-bottom: 1px solid #e9ecef;
  display: flex;
  align-items: center;
  justify-content: center;
  display: none !important;
}

/* Adjust iframe containers for better display */
.word-iframe-container,
.ppt-iframe-container {
  position: relative;
  height: calc(100vh - 180px);
  overflow: hidden;
}

.word-iframe,
.ppt-iframe {
  border: none;
  width: 100%;
  height: 100%;
}

/* Loading overlays */
.word-loading-overlay,
.ppt-loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.spinner {
  animation: spin 1.5s linear infinite;
  font-size: 24px;
  color: #666;
  margin-bottom: 10px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* When sidebar is closed */
.sidebar-closed .main-content {
  width: calc(100% - 50px) !important;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .sidebar.open {
    width: 150px;
  }

  .sidebar-toggle {
    left: 150px;
  }

  .main-content {
    width: calc(100% - 150px) !important;
  }

  .thumbnail-icon-container {
    width: 40px;
    height: 40px;
  }

  .thumbnail-icon {
    font-size: 18px;
  }

  .thumbnail-filename {
    font-size: 11px;
    max-width: 100px;
  }

  .sidebar-closed .main-content {
    width: calc(100% - 40px) !important;
  }
}
/* Add these styles to your SmartLinkViewer.css file */

/* Word document viewer styles */
.word-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  overflow: hidden;
  background-color: #f9f9f9;
}

.word-viewer-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 20px;
  background-color: #3498db;
  color: white;
}

/* here-change */
.word-viewer-header h3 {
  margin: 0;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 80%;
}

.download-button {
  display: flex;
  align-items: center;
  padding: 6px 12px;
  background-color: rgba(255, 255, 255, 0.2);
  color: white;
  border-radius: 4px;
  font-size: 14px;
  text-decoration: none;
  transition: background-color 0.2s;
}

.download-button svg {
  margin-right: 6px;
}

.download-button:hover {
  background-color: rgba(255, 255, 255, 0.3);
  text-decoration: none;
  color: white;
}

.word-iframe-container {
  flex: 1;
  position: relative;
  height: 100%;
  min-height: 500px;
}

.word-iframe {
  width: 100%;
  height: 100%;
  min-height: 500px;
  border: none;
  background: white;
}

.word-loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 10;
}

.word-loading-overlay .spinner {
  font-size: 2rem;
  color: #3498db;
  animation: spin 1.5s linear infinite;
}

.word-loading-overlay p {
  margin-top: 15px;
  color: #555;
  font-weight: 500;
}

/* PowerPoint download prompt styles */
.ppt-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
  background-color: #f9f9f9;
  border-radius: 8px;
}

.ppt-download-prompt {
  text-align: center;
  background-color: white;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
}

.ppt-icon {
  font-size: 60px;
  color: #f39c12;
  margin-bottom: 20px;
}

.ppt-download-prompt h3 {
  margin: 0 0 15px 0;
  font-size: 18px;
  word-break: break-word;
}

.ppt-download-prompt p {
  margin: 0 0 25px 0;
  color: #666;
}

.ppt-download-prompt .download-button {
  display: inline-flex;
  align-items: center;
  padding: 10px 20px;
  background-color: #f39c12;
  color: white;
  border-radius: 4px;
  font-size: 16px;
  text-decoration: none;
  transition: background-color 0.2s;
}

.ppt-download-prompt .download-button:hover {
  background-color: #e67e22;
}

/* Animation for loading spinner */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* SmartLinkView.css */
.synclinkshare-link-view {
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: #f8f9fa;
  color: #333;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

/* Sidebar Styles */
.sidebar {
  width: 220px;
  background-color: #f0f2f5;
  border-right: 1px solid #ddd;
  height: 100%;
  overflow-y: auto;
  transition: transform 0.3s ease, width 0.3s ease;
  display: flex;
  flex-direction: column;
}

.sidebar.closed {
  transform: translateX(-100%);
  width: 0;
}

.sidebar-toggle {
  position: absolute;
  top: 20px;
  left: 220px;
  width: 28px;
  height: 28px;
  background-color: #f0f2f5;
  border: 1px solid #ddd;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 100;
  transition: left 0.3s ease;
  outline: none;
  color: #555;
}

.sidebar-closed .sidebar-toggle {
  left: 10px;
}

.sidebar-header {
  padding: 15px;
  border-bottom: 1px solid #ddd;
}

.sidebar-title {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.file-count {
  font-size: 12px;
  color: #666;
  margin-top: 4px;
}

.file-thumbnails {
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* overflow-y: auto; */
  overflow: scroll;
}

.file-thumbnail {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.15s ease, box-shadow 0.15s ease;
  /* overflow: hidden; */
}

.file-thumbnail:hover {
  transform: translateY(-2px);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

.file-thumbnail.selected {
  box-shadow: 0 0 0 2px #3498db, 0 2px 5px rgba(0, 0, 0, 0.15);
}

.thumbnail-icon-container {
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.thumbnail-icon {
  font-size: 20px;
  color: white;
}

.thumbnail-filename {
  padding: 8px;
  text-align: center;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: white;
  border-top: 1px solid #eee;
}

.page-count-badge {
  position: absolute;
  bottom: 5px;
  right: 5px;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  font-size: 10px;
  padding: 2px 5px;
  border-radius: 3px;
  display: none;
}

/* Main Content Styles */
.main-content {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  transition: width 0.3s ease;
  min-height: 0;
}

/* PDF Viewer Styles */
.pdf-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
}

.pdf-controls {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  padding: 10px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.page-button {
  background-color: #f1f1f1;
  border: none;
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
  margin: 0 5px;
  color: #333;
}

.page-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.page-info {
  margin: 0 10px;
  font-size: 14px;
}

/* Image Viewer Styles */
.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
}

.displayed-image {
  max-width: 100%;
  max-height: 80vh;
  object-fit: contain;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
}

/* Document Viewer Styles */
.doc-container {
  width: 100%;
  height: 80vh;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
  background-color: white;
}

/* Unsupported File Type */
.unsupported-file {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
  text-align: center;
}

.file-icon-large {
  font-size: 72px;
  margin-bottom: 20px;
}

/* Loading and Error States */
.synclinkshare-link-view.loading,
.synclinkshare-link-view.error,
.synclinkshare-link-view.not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  padding: 20px;
}

.spinner {
  animation: spin 1.5s linear infinite;
  font-size: 24px;
  margin-bottom: 10px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-indicator {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.error-container,
.not-found-container {
  max-width: 500px;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
}

/* Footer Styles */
.view-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(5px);
  border-top: 1px solid #ddd;
  padding: 8px 15px;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  z-index: 10;
}

.footer-content {
  display: flex;
  align-items: center;
}

.synclink-brand {
  font-weight: 600;
  margin-right: 20px;
}

.description-text {
  color: #666;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 70%;
}

.pdf-page {
  margin: 0 auto;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.pdf-container {
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 20px 0;
  overflow-x: auto;
}

/* Add a container for the document */
.document-container {
  max-height: calc(100vh - 150px);
  overflow-y: auto;
  display: flex;
  justify-content: center;
  width: 100%;
}

/* Replace your current horizontal PDF CSS with these improved styles */

.pdf-page-number {
  margin-bottom: 10px;
}
/* PDF Container - Horizontal Layout */
.pdf-container-horizontal {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

/* Horizontal scrolling area for PDF pages */
.horizontal-pdf-pages {
  display: flex;
  flex-direction: row;
  overflow-x: auto !important;
  overflow-y: hidden !important; /* Force hiding vertical scrollbar */
  padding: 40px 20px 20px;
  background-color: #f5f5f5;
  min-height: 80vh;
  max-height: calc(100vh - 200px);
  scroll-behavior: smooth;
  gap: 30px; /* Increased gap between pages */
  align-items: flex-start;
  -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #bbb #f5f5f5; /* For Firefox */
  white-space: nowrap;
}

/* Custom scrollbar for Webkit browsers */
.horizontal-pdf-pages::-webkit-scrollbar {
  height: 8px;
  background-color: #f5f5f5;
}

.horizontal-pdf-pages::-webkit-scrollbar-thumb {
  background-color: #bbb;
  border-radius: 4px;
}

.horizontal-pdf-pages::-webkit-scrollbar-thumb:hover {
  background-color: #999;
}

/* Container for each page */
.pdf-page-container {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  background-color: white;
  border-radius: 4px;
  margin-bottom: 10px;
  flex-shrink: 0;
  transition: transform 0.2s ease;
}

/* Highlight current page */
.pdf-page-container.current {
  box-shadow: 0 0 0 3px #3498db, 0 4px 12px rgba(0, 0, 0, 0.2);
  transform: translateY(-5px);
}

/* Page number indicator */
.page-number-indicator {
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 3px 10px;
  font-size: 12px;
  border-radius: 12px;
  z-index: 10;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

/* PDF controls at the top */
.top-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  background-color: #fff;
  border-bottom: 1px solid #eee;
  position: sticky;
  top: 0;
  z-index: 20;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  margin-bottom: 10px;
  border-radius: 4px;
}

/* Scale controls */
.scale-controls {
  display: flex;
  align-items: center;
  margin-left: 20px;
  background-color: #f9f9f9;
  padding: 4px 8px;
  border-radius: 4px;
  border: 1px solid #eee;
}

.scale-button {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.2s;
}

.scale-button:hover:not(:disabled) {
  background-color: #f0f0f0;
}

.scale-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.scale-value {
  margin: 0 8px;
  font-size: 14px;
  min-width: 50px;
  text-align: center;
  font-weight: 500;
}

/* Make sure the PDF page is properly rendered */
.pdf-page {
  display: block !important;
  margin: 0 !important;
  background-color: white;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .sidebar {
    width: 180px;
  }

  .sidebar-toggle {
    left: 180px;
  }

  .sidebar-closed .sidebar-toggle {
    left: 10px;
  }
}

@media (max-width: 480px) {
  .synclinkshare-link-view {
    flex-direction: column;
  }

  .sidebar {
    width: 100%;
    height: 150px;
    border-right: none;
    border-bottom: 1px solid #ddd;
  }

  .sidebar.closed {
    transform: translateY(-100%);
    height: 0;
  }

  .sidebar-toggle {
    top: auto;
    bottom: 10px;
    left: 10px;
    transform: rotate(90deg);
  }

  .sidebar-closed .sidebar-toggle {
    transform: rotate(-90deg);
  }

  .file-thumbnails {
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 8px;
  }

  .file-thumbnail {
    min-width: 100px;
    max-width: 100px;
  }

  .main-content {
    width: 100% !important;
    padding: 10px;
  }
}
