/* WelcomePage.css */
.welcome-page-container {
  width: 100%;
  margin: 0 auto;
  font-family: "Open Sans", sans-serif;
}

.welcome-page-width {
    max-width: var(--max-width);
    margin: 0 auto;
    /* background-color: gray; */
}
.welcome-header {
  padding: 1rem 1rem;
  margin-bottom: 8px;
}

.welcome-title {
  font-size: 28px;
  font-weight: 400;
  color: #212121;
  margin: 0;
}

.welcome-greeting {
  font-weight: 600;
}

.welcome-divider {
  height: 1px;
  background-color: #e0e0e0;
  /* margin: 0 16px; */
}

.empty-state-section {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
  padding: 32px 16px;
}

.empty-state-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 400px;
}

.empty-state-icon {
  margin-bottom: 16px;
  color: #8d95a5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #f5f7fa;
}

.empty-state-title {
  font-size: 20px;
  font-weight: 500;
  color: #424242;
  margin: 0 0 8px 0;
}

.empty-state-description {
  font-size: 16px;
  color: #757575;
  margin: 0 0 24px 0;
  line-height: 1.5;
}

.create-link-button {
  background-color: #2979ff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 12px 24px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.create-link-button:hover {
  background-color: #2962ff;
}

.create-link-button:active {
  background-color: #1a56db;
}

/* Random CSS class names to avoid conflicts */
.syncio-wrapper {
  position: relative;
}

.link-creation-zone {
  margin-top: 16px;
}

.empty-data-container {
  transition: opacity 0.3s ease;
}

.tijani-welcome-section {
  animation: fadeIn 0.5s ease;
}

.smart-link-panel {
  border-radius: 8px;
  overflow: hidden;
}

.welcome-user-greeting {
  display: inline-block;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Responsive styles */
@media (max-width: 768px) {
  .welcome-title {
    font-size: 24px;
  }

  .empty-state-content {
    max-width: 320px;
  }
}
