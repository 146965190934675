.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* App.css */
:root {
  --primary-color: #1e2939;
  --secondary-color: #2d3748;
  --accent-color: #f0f4f9;
  --text-color: #1e293b;
  --light-text: #64748b;
  --border-color: #e2e8f0;
  --white: #ffffff;
  --max-width: 1200px;
  --border-radius: 4px;
  --box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  --transition: all 0.3s ease;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Open Sans", sans-serif;
  color: var(--text-color);
  line-height: 1.5;
  background-color: var(--white);
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

a {
  text-decoration: none;
  color: inherit;
}

ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}

button {
  cursor: pointer;
  border: none;
  background: none;
}

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  border-radius: var(--border-radius);
  font-weight: 500;
  transition: var(--transition);
}

.btn-primary {
  background-color: var(--primary-color);
  color: var(--white);
}

.btn-primary:hover {
  background-color: #0051d9;
}

.btn-secondary {
  background-color: var(--white);
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
}

.btn-secondary:hover {
  background-color: #f0f7ff;
}

.btn-text {
  color: var(--secondary-color);
  padding: 10px;
}

.btn-text:hover {
  color: var(--primary-color);
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* new */

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.app-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: white;
  border-bottom: 1px solid var(--border-color);
  box-shadow: var(--box-shadow);
}

.logo a {
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--primary-color);
}

nav {
  display: flex;
  gap: 1rem;
}

.nav-link {
  padding: 0.5rem 1rem;
  border-radius: 4px;
  transition: var(--transition);
}

.nav-link:hover {
  background-color: var(--secondary-color);
}

.app-content {
  flex: 1;
  padding: 0rem;
  /* max-width: 1200px; */
  margin: 0 auto;
  width: 100%;
}

.app-footer {
  padding: 1rem 2rem;
  background-color: white;
  border-top: 1px solid var(--border-color);
  text-align: center;
  color: var(--light-text);
}

/* Button Styles */
.primary-button {
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 4px;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  cursor: pointer;
  transition: var(--transition);
  display: inline-block;
}

.primary-button:hover {
  background-color: var(--primary-hover);
  color: white;
}

.primary-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* SmartLinkCreator.css */
/* SmartLinkCreator.css */
/* SmartLinkCreator.css */
.sync-link-creator {
  background-color: white;
  border-radius: 8px;
  box-shadow: var(--box-shadow);
  padding: 2rem;
  margin-top: 1rem;
  padding: 1rem;
}

.sync-link-creator h1 {
  margin-bottom: 1.5rem;
  color: var(--primary-color);
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  font-size: 1rem;
  transition: var(--transition);
}

.form-group input:focus,
.form-group textarea:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 0 2px rgba(0, 119, 181, 0.2);
}

.file-upload-section {
  margin-bottom: 1.5rem;
}

.file-upload-container {
  border: 2px dashed var(--border-color);
  border-radius: 8px;
  padding: 2rem;
  text-align: center;
  transition: var(--transition);
}

.file-upload-container:hover {
  border-color: var(--primary-color);
}

.file-input {
  display: none;
}

.file-upload-label {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.upload-icon {
  font-size: 2rem;
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.file-types {
  font-size: 0.85rem;
  color: var(--light-text);
  margin-top: 0.5rem;
}

.small {
  font-size: 0.85rem;
  color: var(--light-text);
}

.files-preview {
  margin-top: 1.5rem;
}

.files-preview h3 {
  margin-bottom: 0.5rem;
}

.file-list {
  margin-top: 1rem;
}

.file-item {
  display: flex;
  align-items: center;
  padding: 0.75rem;
  background-color: var(--secondary-color);
  border-radius: 4px;
  margin-bottom: 0.5rem;
  transition: var(--transition);
}

.file-item:hover {
  background-color: #e9ecef;
}

.file-preview {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
  color: var(--primary-color);
}

.file-preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}

.file-info {
  flex: 1;
}

.file-name {
  display: block;
  font-weight: 500;
}

.file-size {
  font-size: 0.85rem;
  color: var(--light-text);
}

.remove-file {
  background: none;
  border: none;
  color: var(--light-text);
  cursor: pointer;
  padding: 0.25rem;
  transition: var(--transition);
}

.remove-file:hover {
  color: var(--error-color);
}

.form-actions {
  margin-top: 2rem;
}

.error-message {
  background-color: #fff2f2;
  border-left: 4px solid var(--error-color);
  padding: 1rem;
  margin-bottom: 1.5rem;
  border-radius: 4px;
  color: var(--error-color);
}

.success-message {
  background-color: #f0fff4;
  border-left: 4px solid var(--success-color);
  padding: 1.5rem;
  border-radius: 4px;
  margin-bottom: 2rem;
}

.success-message h2 {
  color: var(--success-color);
  margin-bottom: 1rem;
}

.link-container {
  background-color: #f5f7f9;
  padding: 1rem;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0 1.5rem;
}

.copy-button {
  background-color: white;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: var(--transition);
}

.copy-button:hover {
  background-color: var(--secondary-color);
}

/* SmartLinkViewer.css */
.sync-link-viewer {
  background-color: white;
  border-radius: 8px;
  box-shadow: var(--box-shadow);
  height: calc(100vh - 200px);
  display: flex;
  flex-direction: column;
}

.viewer-header {
  padding: 1.5rem;
  border-bottom: 1px solid var(--border-color);
}

.viewer-header h1 {
  color: var(--primary-color);
  margin-bottom: 0.5rem;
}

.description {
  color: var(--light-text);
}

.viewer-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.file-tabs {
  display: flex;
  overflow-x: auto;
  background-color: var(--secondary-color);
  border-bottom: 1px solid var(--border-color);
}

.file-tab {
  padding: 0.75rem 1.5rem;
  background: none;
  border: none;
  border-right: 1px solid var(--border-color);
  cursor: pointer;
  white-space: nowrap;
  transition: var(--transition);
}

.file-tab:hover {
  background-color: #e9ecef;
}

.file-tab.active {
  background-color: white;
  border-bottom: 3px solid var(--primary-color);
  font-weight: 500;
}

.file-viewer-container {
  flex: 1;
  overflow: auto;
  padding: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pdf-viewer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}

.pdf-controls {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  padding: 0.5rem;
  background-color: var(--secondary-color);
  border-radius: 4px;
}

.pdf-controls button {
  background: none;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  color: var(--primary-color);
  transition: var(--transition);
}

.pdf-controls button:disabled {
  color: var(--light-text);
  cursor: not-allowed;
}

.pdf-controls span {
  margin: 0 1rem;
}

.image-viewer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.image-viewer img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.placeholder {
  text-align: center;
  padding: 2rem;
  background-color: var(--secondary-color);
  border-radius: 8px;
}

.download-link {
  display: inline-block;
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background-color: var(--primary-color);
  color: white;
  border-radius: 4px;
  transition: var(--transition);
}

.download-link:hover {
  background-color: var(--primary-hover);
  color: white;
}

.viewer-footer {
  padding: 1rem;
  border-top: 1px solid var(--border-color);
  display: flex;
  justify-content: center;
}

.pagination {
  display: flex;
  align-items: center;
}

.nav-button {
  background: none;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  color: var(--primary-color);
  transition: var(--transition);
}

.nav-button:disabled {
  color: var(--light-text);
  cursor: not-allowed;
}

.loading-container,
.error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50vh;
}

.loading {
  text-align: center;
  padding: 2rem;
}
