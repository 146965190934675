/* LoginScreen.css */
.login-container {
  display: flex;
  min-height: 100vh;
  font-family: "Open Sans", sans-serif;
}

/* Left Panel */
.left-panel {
  flex: 1;
  background: linear-gradient(135deg, #334bd1 0%, #04208f 100%);
  color: white;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

.left-panel::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000" preserveAspectRatio="none"><path d="M0,0 L1000,0 L1000,1000 L0,1000 Z" fill="none" stroke="%23ffffff20" stroke-width="1"/><path d="M0,333 L1000,0 M0,666 L1000,333 M0,1000 L1000,666" fill="none" stroke="%23ffffff20" stroke-width="1"/></svg>');
  background-size: cover;
  opacity: 0.15;
}

.logo-container {
  margin-bottom: 4rem;
}

.logo-icon {
  font-size: 2.5rem;
}

.welcome-text {
  flex: 1;
}

.welcome-text h1 {
  font-size: 3.5rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
  line-height: 1.2;
}

.wave-emoji {
  font-size: 3rem;
}

.welcome-text p {
  font-size: 1.25rem;
  line-height: 1.6;
  max-width: 80%;
  margin-bottom: 2rem;
}

.copyright {
  font-size: 0.875rem;
  opacity: 0.7;
}

/* Right Panel */
.right-panel {
  flex: 1;
  background-color: #f5f5f7;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-form-container {
  width: 100%;
  max-width: 420px;
  padding: 2rem;
}

.brand-name {
  font-size: 1.5rem;
  font-weight: 700;
  color: #333;
  margin-bottom: 3rem;
}

.login-welcome h1 {
  font-size: 2rem;
  font-weight: 700;
  color: #333;
  margin-bottom: 0.75rem;
}

.login-welcome p {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 2rem;
  line-height: 1.5;
}

.create-account-link {
  color: #334bd1;
  text-decoration: none;
  font-weight: 600;
}

.create-account-link:hover {
  text-decoration: underline;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-control {
  width: 100%;
  padding: 0.75rem 1rem;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
  transition: border-color 0.2s;
}

.form-control:focus {
  outline: none;
  border-color: #334bd1;
}

.btn-login {
  width: 100%;
  padding: 0.85rem;
  background-color: #222;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  margin-bottom: 1rem;
  transition: background-color 0.2s;
}

.btn-login:hover {
  background-color: #000;
}

.btn-google {
  width: 100%;
  padding: 0.75rem;
  background-color: white;
  color: #333;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;
  transition: background-color 0.2s;
}

.btn-google:hover {
  background-color: #f5f5f5;
}

.google-icon {
  width: 18px;
  height: 18px;
  margin-right: 0.75rem;
}

.forgot-password {
  text-align: center;
  font-size: 0.875rem;
  color: #666;
}

.click-here {
  margin-left: 0.5rem;
  color: #334bd1;
  font-weight: 600;
  text-decoration: none;
}

.click-here:hover {
  text-decoration: underline;
}

/* Responsive styles */
@media (max-width: 992px) {
  .login-container {
    flex-direction: column;
  }

  .left-panel {
    padding: 2rem;
    display: none;
  }

  .welcome-text h1 {
    font-size: 2.5rem;
  }

  .welcome-text p {
    max-width: 100%;
  }
}

@media (max-width: 576px) {
  .left-panel {
    padding: 1.5rem;
    display: none;
  }

  .welcome-text h1 {
    font-size: 2rem;
  }

  .login-form-container {
    padding: 1.5rem;
  }
}
