/* CSS for the beautified link created section */
.link-created-card {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    margin: 25px 0;
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .link-created-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.12);
  }
  
  .link-created-header {
    background: linear-gradient(135deg, #3498db, #2980b9);
    color: white;
    padding: 18px 24px;
    display: flex;
    align-items: center;
    gap: 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  
  .link-created-header h3 {
    margin: 0;
    font-size: 20px;
    font-weight: 600;
  }
  
  .link-created-icon {
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
  }
  
  .link-created-content {
    padding: 24px;
  }
  
  .link-created-message {
    color: #555;
    margin-bottom: 16px;
    font-size: 16px;
  }
  
  .link-input-container {
    display: flex;
    margin-bottom: 12px;
    position: relative;
  }
  
  .link-input {
    flex-grow: 1;
    padding: 12px 16px;
    font-size: 16px;
    border: 2px solid #e0e0e0;
    border-radius: 6px 0 0 6px;
    color: #333;
    background-color: #f9f9f9;
    transition: border-color 0.2s ease;
  }
  
  .link-input:focus {
    outline: none;
    border-color: #3498db;
    box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.2);
  }
  
  .copy-link-button {
    padding: 12px 20px;
    background-color: #3498db;
    color: white;
    border: none;
    border-radius: 0 6px 6px 0;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    transition: background-color 0.2s ease;
  }
  
  .copy-link-button:hover {
    background-color: #2980b9;
  }
  
  .link-created-footer {
    padding: 16px 24px;
    background-color: #f5f7fa;
    display: flex;
    gap: 15px;
    justify-content: center;
    border-top: 1px solid #e0e0e0;
  }
  
  .view-link-button, .create-new-link-button {
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 6px;
    border: none;
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .view-link-button {
    background-color: #3498db;
    color: white;
  }
  
  .view-link-button:hover {
    background-color: #2980b9;
  }
  
  .create-new-link-button {
    background-color: #ecf0f1;
    color: #34495e;
    border: 1px solid #bdc3c7;
  }
  
  .create-new-link-button:hover {
    background-color: #e0e6e8;
  }
  
  /* Animation when link is copied */
  @keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.1); }
    100% { transform: scale(1); }
  }
  
  .copy-link-button:active {
    animation: pulse 0.3s ease-in-out;
  }