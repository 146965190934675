
/* header */

/* Header.css - Fixed desktop and mobile layouts */
.header {
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: var(--white);
  border-bottom: 1px solid var(--border-color);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

/* Desktop layout */
.header-container {
  max-width: var(--max-width);
  margin: 0 auto;
  padding: 0 24px;
  height: 64px;
  display: flex;
  align-items: center;
}

.header-top-bar {
  display: flex;
  align-items: center;
}

.logo {
  display: flex;
  align-items: center;
}

.logo img {
  height: 24px;
  margin-right: 8px;
}

.logo-text {
  font-weight: 600;
  font-size: 18px;
  color: var(--secondary-color);
}

/* Hide mobile toggle by default */
.mobile-menu-toggle {
  display: none;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

/* Hamburger icon */
.hamburger {
  position: relative;
  width: 24px;
  height: 2px;
  background-color: var(--secondary-color);
  transition: var(--transition);
}

.hamburger::before,
.hamburger::after {
  content: '';
  position: absolute;
  width: 24px;
  height: 2px;
  background-color: var(--secondary-color);
  transition: var(--transition);
}

.hamburger::before {
  top: -8px;
}

.hamburger::after {
  bottom: -8px;
}

.hamburger.open {
  background-color: transparent;
}

.hamburger.open::before {
  top: 0;
  transform: rotate(45deg);
}

.hamburger.open::after {
  bottom: 0;
  transform: rotate(-45deg);
}

/* Nav section layout */
.nav-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
}

/* Main navigation */
.main-nav {
  margin-left: 40px;
}

.main-nav ul {
  display: flex;
  gap: 24px;
  padding: 0;
  margin: 0;
  list-style: none;
}

.main-nav ul li button {
  background: none;
  border: none;
  color: var(--secondary-color);
  font-weight: 500;
  padding: 8px 0;
  cursor: pointer;
  font-size: 16px;
  transition: var(--transition);
}

.main-nav ul li button:hover {
  color: var(--primary-color);
}

/* Actions (sign in, demo, etc.) */
.actions {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-left: auto;
}

.language-selector button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: none;
  border: none;
  cursor: pointer;
  transition: var(--transition);
}

.language-selector button:hover {
  background-color: var(--accent-color);
}

/* MOBILE LAYOUT */
@media (max-width: 940px) {
  /* Reset container */
  .header-container {
    height: auto;
    flex-direction: column;
    align-items: stretch;
    padding: 0;
  }

  /* Top bar with logo and toggle */
  .header-top-bar {
    padding: 16px 24px;
    justify-content: space-between;
    border-bottom: 1px solid var(--border-color);
  }

  /* Show mobile toggle */
  .mobile-menu-toggle {
    display: flex;
  }

  /* Hide nav section by default */
  .nav-section {
    display: none;
    flex-direction: column;
    align-items: stretch;
  }

  /* Show nav section when menu is open */
  .nav-section.open {
    display: flex;
  }

  /* Main nav adjustments */
  .main-nav {
    margin-left: 0;
    width: 100%;
  }

  .main-nav ul {
    flex-direction: column;
    gap: 0;
  }

  .main-nav ul li {
    border-bottom: 1px solid var(--border-color);
  }

  .main-nav ul li button {
    display: block;
    width: 100%;
    text-align: left;
    padding: 16px 24px;
  }

  /* Actions adjustments */
  .actions {
    width: 100%;
    flex-direction: column;
    gap: 12px;
    padding: 16px 24px 24px;
    margin-left: 0;
  }

  .actions button {
    width: 100%;
    justify-content: center;
  }

  .language-selector {
    width: 100%;
  }

  .language-selector button {
    width: 100%;
    justify-content: flex-start;
    padding-left: 0;
    border-radius: 0;
  }
}

@media (max-width: 480px) {
  .header-top-bar {
    padding: 12px 16px;
  }

  .logo-text {
    font-size: 16px;
  }

  .logo img {
    height: 20px;
  }

  .main-nav ul li button,
  .actions button {
    padding: 14px 16px;
  }
}

/* header */

/* Hero.css */
.hero {
  background-color: var(--accent-color);
  padding: 80px 24px;
}

.hero-container {
  display: flex;
  max-width: var(--max-width);
  margin: 0 auto;
  gap: 60px;
}

.hero-content {
  flex: 1;
  max-width: 600px;
}

.hero-logo {
  margin-bottom: 24px;
}

.hero-logo img {
  height: 32px;
}

.hero h1 {
  font-size: 48px;
  line-height: 1.2;
  margin-bottom: 24px;
  color: var(--text-color);
}

.hero p {
  font-size: 18px;
  color: var(--light-text);
  margin-bottom: 32px;
  max-width: 500px;
}

.btn-hero {
  padding: 14px 28px;
  font-size: 16px;
}

.icon-arrow-right {
  margin-left: 8px;
}

.hero-image {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Platform.css */
.platform {
  padding: 80px 24px;
}

.platform-container {
  max-width: var(--max-width);
  margin: 0 auto;
  text-align: center;
}

.platform h2 {
  font-size: 36px;
  margin-bottom: 16px;
}

.platform > p {
  max-width: 600px;
  margin: 0 auto 60px;
  color: var(--light-text);
}

.feature-cards {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;
}

.feature-card {
  padding: 24px;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  text-align: left;
  height: 100%;
  transition: var(--transition);
}

.feature-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.feature-card h3 {
  margin-bottom: 12px;
  color: var(--secondary-color);
}

.feature-card p {
  color: var(--light-text);
  font-size: 14px;
}

/* VirtualDataRooms.css */
.vdr {
  background-color: var(--accent-color);
  padding: 80px 24px;
}

.vdr-container {
  max-width: var(--max-width);
  margin: 0 auto;
  display: flex;
  gap: 60px;
}

.vdr-content {
  flex: 1;
  max-width: 500px;
}

.vdr-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  background-color: var(--primary-color);
  border-radius: 50%;
  margin-bottom: 24px;
}

.vdr-icon i {
  color: var(--white);
  font-size: 24px;
}

.vdr h2 {
  font-size: 36px;
  line-height: 1.3;
  margin-bottom: 24px;
}

.vdr p {
  color: var(--light-text);
  font-size: 18px;
}

.vdr-image {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Testimonials.css */
.testimonials {
  padding: 80px 24px;
}

.testimonials-container {
  max-width: var(--max-width);
  margin: 0 auto;
}

.testimonial {
  margin-bottom: 60px;
  text-align: center;
}

.testimonial:last-child {
  margin-bottom: 0;
}

.testimonial p {
  font-size: 32px;
  font-weight: 300;
  line-height: 1.3;
  max-width: 800px;
  margin: 0 auto 24px;
}

.testimonial-author {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.testimonial-author img {
  width: 48px;
  height: 48px;
  border-radius: 50%;
}

.testimonial-author p {
  font-size: 16px;
  margin: 0;
  color: var(--light-text);
}

/* Features.css */
.features {
  padding: 80px 24px;
  background-color: var(--accent-color);
}

.features-container {
  max-width: var(--max-width);
  margin: 0 auto;
}

.features h2 {
  text-align: center;
  font-size: 36px;
  margin-bottom: 60px;
}

.feature-boxes {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
}

.feature-box {
  background-color: var(--white);
  border-radius: var(--border-radius);
  overflow: hidden;
  box-shadow: var(--box-shadow);
}

.feature-video {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 aspect ratio */
  background-color: #000;
  overflow: hidden;
}

.feature-video img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60px;
  height: 60px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.play-btn::after {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 0 10px 18px;
  border-color: transparent transparent transparent var(--white);
  margin-left: 4px;
}

.feature-box h3 {
  padding: 24px 24px 12px;
  font-size: 20px;
}

.feature-box p {
  padding: 0 24px 16px;
  color: var(--light-text);
}

.feature-link {
  display: block;
  padding: 0 24px 24px;
  color: var(--primary-color);
  font-weight: 500;
}

.feature-link:hover {
  text-decoration: underline;
}

/* Trusted.css */
.trusted {
  padding: 80px 24px;
}

.trusted-container {
  max-width: var(--max-width);
  margin: 0 auto;
  text-align: center;
}

.trusted h2 {
  font-size: 36px;
  max-width: 800px;
  margin: 0 auto 60px;
}

.trusted-logos {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  margin-bottom: 60px;
}

.trusted-logos img {
  height: 32px;
  opacity: 0.7;
  transition: var(--transition);
}

.trusted-logos img:hover {
  opacity: 1;
}

.try-free {
  background-color: var(--primary-color);
  color: var(--white);
  padding: 40px;
  border-radius: var(--border-radius);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.try-free p {
  font-size: 20px;
  font-weight: 500;
}

.try-free .btn-secondary {
  background-color: var(--white);
  color: var(--primary-color);
  border: none;
  font-weight: 600;
}

/* Footer.css */
.footer {
  background-color: #000;
  color: var(--white);
  padding: 60px 24px 40px;
}

.footer-container {
  max-width: var(--max-width);
  margin: 0 auto;
}

.footer-logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
}

.footer-logo img {
  height: 40px;
  margin-bottom: 12px;
}

.footer-logo span {
  color: #aaa;
  font-size: 14px;
}

.footer-links {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 40px;
  margin-bottom: 60px;
}

.footer-column h4 {
  font-size: 14px;
  color: #888;
  margin-bottom: 20px;
}

.footer-column ul li {
  margin-bottom: 12px;
}

.footer-column ul li a {
  color: #ddd;
  font-size: 14px;
  transition: var(--transition);
}

.footer-column ul li a:hover {
  color: var(--white);
}

.footer-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
  border-top: 1px solid #333;
}

.footer-bottom p {
  color: #777;
  font-size: 14px;
}

.social-links {
  display: flex;
  gap: 16px;
}

.social-links a {
  color: #777;
  font-size: 18px;
  transition: var(--transition);
}

.social-links a:hover {
  color: var(--white);
}

/* Media Queries for Responsiveness */
@media (max-width: 1024px) {
  .hero h1 {
    font-size: 40px;
  }

  .feature-cards {
    grid-template-columns: repeat(3, 1fr);
  }

  .feature-cards > div:nth-child(4),
  .feature-cards > div:nth-child(5) {
    grid-column: span 3;
    max-width: 400px;
    margin: 0 auto;
  }
}

@media (max-width: 768px) {
  .header-container {
    flex-direction: column;
    gap: 16px;
  }

  .main-nav ul {
    flex-wrap: wrap;
    justify-content: center;
  }

  .hero-container {
    flex-direction: column;
  }

  .feature-cards {
    grid-template-columns: 1fr;
  }

  .feature-cards > div:nth-child(4),
  .feature-cards > div:nth-child(5) {
    grid-column: auto;
  }

  .vdr-container {
    flex-direction: column;
  }

  .feature-boxes {
    grid-template-columns: 1fr;
  }

  .trusted-logos {
    flex-wrap: wrap;
    justify-content: center;
  }

  .try-free {
    flex-direction: column;
    gap: 20px;
  }

  .footer-links {
    grid-template-columns: 1fr 1fr;
    gap: 30px;
  }

  .footer-bottom {
    flex-direction: column;
    gap: 20px;
  }
}

@media (max-width: 480px) {
  .hero h1 {
    font-size: 32px;
  }

  .testimonial p {
    font-size: 24px;
  }

  .footer-links {
    grid-template-columns: 1fr;
  }
}
