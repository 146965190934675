/* Form actions container */
.form-actions {
  display: flex;
  align-items: center;
  margin: 20px 0;
  min-height: 50px;
}

/* Submit button with fixed dimensions */
.submit-button {
  min-width: 180px;
  height: 48px;
  padding: 0 20px;
  box-sizing: border-box; /* Important: ensures padding doesn't affect dimensions */
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  border: none;
  border-radius: 4px;
  background-color: #3498db;
  color: white;
  cursor: pointer;
  transition: background-color 0.2s;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.submit-button:hover:not(:disabled) {
  background-color: #2980b9;
}

.submit-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.submit-button.loading {
  background-color: #2980b9;
}

/* File type styles and card-based layout */
.upload-text {
  color: white;
}

.attachments-container {
  margin-top: 20px;
}

.file-count {
  font-size: 0.9rem;
  color: #6c757d;
  font-weight: normal;
}

.file-cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  gap: 16px;
  margin-top: 16px;
}

.file-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: all 0.2s ease;
  cursor: grab;
  position: relative;
}

.file-card:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  transform: translateY(-2px);
}

.file-card.dragging {
  opacity: 0.7;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.file-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  color: white;
}

.file-type-icon {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
}

.file-type-label {
  margin-left: 8px;
  font-size: 0.85rem;
  font-weight: 500;
}

.file-card-controls {
  display: flex;
}

.remove-file-card {
  background: rgba(255, 255, 255, 0.2);
  border: none;
  color: white;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 0.75rem;
  transition: background-color 0.2s;
}

.remove-file-card:hover {
  background: rgba(255, 255, 255, 0.3);
}

.file-card-content {
  padding: 16px;
}

.file-card-name {
  font-weight: 500;
  color: #333;
  margin-bottom: 8px;
  word-break: break-word;
  font-size: 0.95rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.file-card-size {
  color: #6c757d;
  font-size: 0.8rem;
}

.file-card-footer {
  padding: 8px 16px;
  background-color: #f8f9fa;
  border-top: 1px solid #e9ecef;
}

.file-order-controls {
  display: flex;
  justify-content: center;
  align-items: center;
}

.file-position {
  margin: 0 8px;
  font-size: 0.85rem;
  color: #6c757d;
  width: 30px;
  text-align: center;
}

.move-file {
  background: none;
  border: none;
  color: #6c757d;
  padding: 4px 8px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 0.85rem;
}

.move-file:hover:not(.disabled) {
  background-color: #e9ecef;
  color: #495057;
}

.move-file.disabled {
  color: #ced4da;
  cursor: not-allowed;
}

/* Update the upload area to separate it from attachments */
.upload-area {
  border: 2px dashed #ced4da;
  border-radius: 6px;
  padding: 24px;
  text-align: center;
  background-color: #f8f9fa;
  transition: all 0.2s ease-in-out;
  margin-bottom: 20px;
}

.upload-area:hover {
  border-color: #6c757d;
  background-color: #f1f3f5;
}

.upload-label {
  background-color: #4361ee;
  color: white;
  display: inline-flex;
  align-items: center;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
}

.upload-label svg {
  margin-right: 8px;
}

.reorder-hint {
  color: #6c757d;
  font-size: 0.85rem;
  text-align: center;
  margin-bottom: 16px;
  font-style: italic;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .file-cards {
    grid-template-columns: 1fr;
  }
}

/* Improve spacing between sections */
.form-group {
  margin-bottom: 24px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  color: #495057;
}