/* MyLinksPage.css */

.my-links-container {
  max-width: var(--max-width);
  margin: 0 auto;
  margin-bottom: 5rem;
  /* margin-top: 5rem; */
  padding: 30px 20px;
  font-family: "Open Sans", sans-serif;
}

.my-links-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.my-links-header h1 {
  font-size: 32px;
  color: #333;
  margin: 0;
  font-weight: 600;
}

.create-link-button {
  background-color: #3498db;
  color: white;
  text-decoration: none;
  padding: 12px 20px;
  border-radius: 6px;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  font-weight: 500;
  transition: all 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.create-link-button:hover {
  background-color: #2980b9;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.synclinkshare-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
  gap: 25px;
  margin-top: 20px;
}

.synclinkshare-card {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border-top: 5px solid #3498db; /* Default color, will be overridden */
}

.synclinkshare-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

.synclinkshare-card-header {
  padding: 20px 20px 10px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.synclinkshare-title {
  margin: 0;
  font-size: 20px;
  font-weight: 600;
  color: #333;
  width: 75%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.synclinkshare-actions {
  display: flex;
  gap: 10px;
}

.edit-button,
.delete-button {
  width: 32px;
  height: 32px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666;
  background: transparent;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;
}

.edit-button {
  margin-top: -0rem !important
}

.edit-button {
  color: #3498db;
}

.edit-button:hover {
  background-color: #edf7fd;
  color: #2980b9;
}

.delete-button {
  color: #e74c3c;
}

.delete-button:hover {
  background-color: #feeaea;
  color: #c0392b;
}

.delete-button.deleting {
  opacity: 0.5;
  cursor: not-allowed;
}

.synclinkshare-content {
  padding: 0 20px 15px;
  flex-grow: 1;
}

.synclinkshare-description {
  color: #666;
  font-size: 14px;
  line-height: 1.5;
  margin-top: 5px;
  margin-bottom: 15px;
  height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.synclinkshare-details {
  display: flex;
  margin-top: 15px;
  gap: 25px;
}

.detail-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.detail-icon {
  font-size: 18px;
  color: #3498db;
  margin-bottom: 4px;
}

.detail-value {
  font-size: 18px;
  font-weight: 600;
  color: #333;
}

.detail-label {
  font-size: 12px;
  color: #777;
  margin-top: 2px;
}

.synclinkshare-footer {
  background-color: #f8f9fa;
  padding: 15px 20px;
  border-top: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.synclinkshare-meta {
  display: flex;
  gap: 20px;
}

.meta-item {
  display: flex;
  flex-direction: column;
}

.meta-label {
  font-size: 12px;
  color: #777;
}

.meta-value {
  font-size: 14px;
  font-weight: 500;
  color: #333;
}

.view-link-button {
  background-color: #f8f9fa;
  color: #3498db;
  border: 1px solid #3498db;
  padding: 8px 15px;
  border-radius: 4px;
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.2s ease;
}

.view-link-button:hover {
  background-color: #3498db;
  color: white;
}

.no-links {
  text-align: center;
  padding: 40px 20px;
  background-color: #f8f9fa;
  border-radius: 10px;
  margin-top: 30px;
}

.no-links p {
  font-size: 18px;
  color: #666;
  margin-bottom: 20px;
}

.create-first-link {
  background-color: #3498db;
  color: white;
  text-decoration: none;
  padding: 12px 24px;
  border-radius: 6px;
  font-weight: 500;
  transition: all 0.2s ease;
  display: inline-block;
}

.create-first-link:hover {
  background-color: #2980b9;
}

/* Loading state */
.my-links-container.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 300px;
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-bottom: 20px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Error state */
.my-links-container.error {
  text-align: center;
  padding: 40px 20px;
}

.retry-button {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;
}

.retry-button:hover {
  background-color: #2980b9;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .my-links-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }

  .synclinkshare-grid {
    grid-template-columns: 1fr;
  }

  .synclinkshare-footer {
    flex-direction: column;
    gap: 15px;
    align-items: flex-start;
  }

  .view-link-button {
    align-self: flex-end;
  }
}
