/* Add these styles to your SmartLinkCreator.css file */

/* Since we're reusing most of the CSS from SmartLinkCreator,
   we only need to add/modify a few styles */
.selectFiles {
  color: white !important;
}
/* Update page heading */
.synclinkshare-link-creator .creator-header h1 {
  margin-bottom: 10px;
}

/* Add Cancel Button Style */
.cancel-button {
  background-color: #f1f1f1;
  border: 1px solid #d3d3d3;
  color: #333;
  font-size: 16px;
  padding: 12px 24px;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 15px;
  transition: all 0.2s ease;
}

.cancel-button:hover {
  background-color: #e0e0e0;
}

.cancel-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

/* Modified form actions to accommodate cancel button */
.form-actions {
  display: flex;
  justify-content: flex-start;
  margin-top: 30px;
}

/* Update notification for changed files */
.file-card.modified {
  border: 2px solid #3498db;
}

.file-card.new {
  border: 2px solid #2ecc71;
}

/* Optional status badges for files */
.file-status-badge {
  position: absolute;
  top: 5px;
  right: 30px;
  padding: 2px 6px;
  font-size: 12px;
  border-radius: 3px;
  color: white;
}

.file-status-badge.new {
  background-color: #2ecc71;
}

.file-status-badge.existing {
  background-color: #3498db;
}

.file-status-badge.modified {
  background-color: #f39c12;
}

/* Base styles for the SmartLinkViewer */

/* Smaller sidebar/minimap */
.sidebar.open {
    width: 180px; /* Reduced from 220px */
    height: 100vh; /* Full viewport height */
    display: flex;
    flex-direction: column;
  }
  
  .sidebar-header {
    flex-shrink: 0; /* Don't allow header to shrink */
    padding: 15px;
    border-bottom: 1px solid #e0e0e0;
  }
  
  .file-thumbnails {
    flex-grow: 1; /* Take remaining space */
    overflow-y: auto; /* Enable vertical scrolling */
    padding: 10px;
    scrollbar-width: thin; /* For Firefox */
  }
  
  /* Styling for Webkit browsers (Chrome, Safari, Edge) */
  .file-thumbnails::-webkit-scrollbar {
    width: 6px;
  }
  
  .file-thumbnails::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  .file-thumbnails::-webkit-scrollbar-thumb {
    background: #cdcdcd;
    border-radius: 3px;
  }
  
  .file-thumbnails::-webkit-scrollbar-thumb:hover {
    background: #b0b0b0;
  }
  
  /* Update sidebar toggle button positioning */
  .sidebar-toggle {
    left: 180px; /* Match with sidebar width */
  }
  
  /* Make thumbnail items smaller and set consistent sizes */
  .file-thumbnail {
    margin-bottom: 12px; /* Reduced spacing */
    min-height: 70px; /* Ensure consistent height */
    position: relative;
  }
  
  .thumbnail-icon-container {
    width: 48px;  /* Smaller icons */
    height: 48px;
    position: relative; /* For the page count badge */
  }
  
  .thumbnail-icon {
    font-size: 20px; /* Smaller icon font */
  }
  
  .thumbnail-filename {
    font-size: 12px; /* Smaller font for filenames */
    max-width: 130px; /* Narrower text area */
  }
  
  .page-count-badge {
    font-size: 10px;
    padding: 1px 4px;
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    border-top-left-radius: 3px;
    line-height: 1;
  }
  
  /* PDF viewer header styles (similar to Word and PowerPoint) */
  .pdf-viewer-header, .word-viewer-header, .ppt-viewer-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    background-color: #f8f9fa;
    border-bottom: 1px solid #e9ecef;
  }
  
  .pdf-viewer-header h3, .word-viewer-header h3, .ppt-viewer-header h3 {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    color: #333;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  /* Document and presentation navigation info */
  .doc-navigation, .ppt-navigation {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 0;
    background: #f5f5f5;
    border-bottom: 1px solid #ddd;
    font-size: 13px;
    color: #555;
  }
  
  .slide-count, .page-count {
    font-weight: 500;
  }
  
  /* PowerPoint fallback view styles */
  .ppt-download-prompt, .word-download-prompt {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    max-width: 500px;
    margin: 2rem auto;
  }
  
  .ppt-icon, .word-icon {
    font-size: 4rem;
    margin-bottom: 1rem;
  }
  
  .ppt-icon {
    color: #f39c12;
  }
  
  .word-icon {
    color: #3498db;
  }
  
  .ppt-download-prompt h3, .word-download-prompt h3 {
    margin-bottom: 1rem;
    word-break: break-word;
  }
  
  .ppt-download-prompt .download-button, .word-download-prompt .download-button {
    margin-top: 1.5rem;
    padding: 0.75rem 1.5rem;
  }
  
  /* Adjust main content area width */
  .main-content {
    width: calc(100% - 180px) !important; /* Force the smaller width when sidebar is open */
  }
  

  .update-color {
    padding: 0.7rem 2rem !important;

    
  }

  .cancel-color {
    padding: 0.7rem 2rem !important;
  }
  /* Refined download button styles */
  .download-button {
    display: inline-flex;
    align-items: center;
    gap: 5px;
    padding: 6px 12px;
    background-color: #f8f9fa;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    color: #495057;
    text-decoration: none;
    font-size: 14px;
    transition: all 0.2s ease;
  }
  
  .download-button:hover {
    background-color: #e9ecef;
    border-color: #ced4da;
    color: #212529;
  }
  
  .download-button svg {
    font-size: 14px;
  }
  
  /* Adjust PDF controls to work with the header */
  .pdf-controls {
    padding: 8px 15px;
    background-color: #f8f9fa;
    border-bottom: 1px solid #e9ecef;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  /* Adjust iframe containers for better display */
  .word-iframe-container, .ppt-iframe-container {
    position: relative;
    height: calc(100vh - 180px);
    overflow: hidden;
  }
  
  .word-iframe, .ppt-iframe {
    border: none;
    width: 100%;
    height: 100%;
  }
  
  /* Loading overlays */
  .word-loading-overlay, .ppt-loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 10;
  }
  
  .spinner {
    animation: spin 1.5s linear infinite;
    font-size: 24px;
    color: #666;
    margin-bottom: 10px;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  /* When sidebar is closed */
  .sidebar-closed .main-content {
    width: calc(100% - 50px) !important;
  }
  
  /* EDIT MODE STYLES */
  
  /* Edit button styles */
  .edit-button {
    display: inline-flex;
    align-items: center;
    gap: 5px;
    padding: 5px 10px;
    background-color: #f8f9fa;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    color: #495057;
    font-size: 13px;
    margin-top: 10px;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .edit-button:hover {
    background-color: #e9ecef;
    border-color: #ced4da;
  }
  
  .edit-controls {
    display: flex;
    gap: 8px;
    margin-top: 10px;
  }
  
  .save-button, .cancel-button, .delete-button {
    padding: 5px 10px;
    font-size: 13px;
    border-radius: 4px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    gap: 5px;
    transition: all 0.2s ease;
  }
  
  .save-button {
    background-color: #28a745;
    border: 1px solid #28a745;
    color: white;
  }
  
  .save-button:hover {
    background-color: #218838;
    border-color: #1e7e34;
  }
  
  .cancel-button {
    background-color: #f8f9fa;
    border: 1px solid #dee2e6;
    color: #495057;
  }
  
  .cancel-button:hover {
    background-color: #e9ecef;
    border-color: #ced4da;
  }
  
  .delete-button {
    background-color: #dc3545;
    border: 1px solid #dc3545;
    color: white;
  }
  
  .delete-button:hover {
    background-color: #c82333;
    border-color: #bd2130;
  }
  
  /* Disable buttons while saving */
  .save-button:disabled, .cancel-button:disabled, .delete-button:disabled {
    opacity: 0.65;
    cursor: not-allowed;
  }
  
  /* File thumbnail in edit mode */
  .file-thumbnail.edit-mode {
    cursor: grab;
    position: relative;
    border: 1px dashed transparent;
    padding: 5px;
    background-color: #f8f9fa;
  }
  
  .file-thumbnail.edit-mode:hover {
    border-color: #ced4da;
  }
  
  .file-thumbnail.dragging {
    opacity: 0.5;
    cursor: grabbing;
  }
  
  /* File edit controls */
  .file-edit-controls {
    position: absolute;
    right: 5px;
    top: 5px;
    display: flex;
    gap: 5px;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 4px;
    padding: 2px;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.2s ease;
  }
  
  .file-thumbnail.edit-mode:hover .file-edit-controls {
    visibility: visible;
    opacity: 1;
  }
  
  .replace-file-button, .delete-file-button, .edit-name-button {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    color: #495057;
    padding: 0;
    font-size: 12px;
  }
  
  .replace-file-button:hover, .delete-file-button:hover, .edit-name-button:hover {
    background-color: #e9ecef;
  }
  
  .delete-file-button:hover {
    color: #dc3545;
  }
  
  .replace-file-button:hover {
    color: #28a745;
  }
  
  .edit-name-button {
    margin-left: 5px;
    font-size: 10px;
    visibility: hidden;
  }
  
  .thumbnail-filename:hover .edit-name-button {
    visibility: visible;
  }
  
  /* Edit filename container */
  .edit-filename-container {
    margin-top: 5px;
    width: 100%;
  }
  
  .edit-filename-container input {
    width: 100%;
    padding: 4px 6px;
    font-size: 12px;
    border: 1px solid #ced4da;
    border-radius: 3px;
  }
  
  /* Upload progress indicator */
  .upload-progress {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: #e9ecef;
    overflow: hidden;
  }
  
  .progress-bar {
    height: 100%;
    background-color: #28a745;
    transition: width 0.3s ease;
  }
  
  /* Add files button */
  .add-files-container {
    margin-top: 15px;
    padding: 10px;
    text-align: center;
  }
  
  .add-files-button {
    display: inline-flex;
    align-items: center;
    gap: 5px;
    padding: 8px 12px;
    background-color: #f8f9fa;
    border: 2px dashed #dee2e6;
    border-radius: 4px;
    color: #495057;
    font-size: 13px;
    cursor: pointer;
    transition: all 0.2s ease;
    width: 100%;
    justify-content: center;
  }
  
  .add-files-button:hover {
    background-color: #e9ecef;
    border-color: #ced4da;
  }
  
  /* Modal styles */
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modal-content {
    background-color: white;
    border-radius: 6px;
    padding: 20px;
    width: 400px;
    max-width: 90%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  }
  
  .modal-content h3 {
    margin-top: 0;
    color: #dc3545;
  }
  
  .modal-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 20px;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .sidebar.open {
      width: 150px;
    }
    
    .sidebar-toggle {
      left: 150px;
    }
    
    .main-content {
      width: calc(100% - 150px) !important;
    }
    
    .thumbnail-icon-container {
      width: 40px;
      height: 40px;
    }
    
    .thumbnail-icon {
      font-size: 18px;
    }
    
    .thumbnail-filename {
      font-size: 11px;
      max-width: 100px;
    }
    
    .sidebar-closed .main-content {
      width: calc(100% - 40px) !important;
    }
    
    .edit-controls {
      flex-direction: column;
    }
  }