.file-upload-status-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 360px;
    max-width: 90vw;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
    overflow: hidden;
    z-index: 1000;
    animation: slide-up 0.3s ease-out;
    border: 1px solid #e0e0e0;
  }
  
  @keyframes slide-up {
    from {
      transform: translateY(100%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  .file-upload-status-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 15px;
    background-color: #f8f9fa;
    border-bottom: 1px solid #e0e0e0;
  }
  
  .file-upload-status-title {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    color: #333;
  }
  
  .upload-icon {
    margin-right: 10px;
    color: #3498db;
  }
  
  .close-button {
    background: none;
    border: none;
    color: #777;
    cursor: pointer;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: background-color 0.2s;
  }
  
  .close-button:hover {
    background-color: #e9ecef;
    color: #333;
  }
  
  .file-upload-list {
    max-height: 300px;
    overflow-y: auto;
  }
  
  .file-upload-item {
    display: flex;
    align-items: center;
    padding: 12px 15px;
    border-bottom: 1px solid #f0f0f0;
    transition: background-color 0.2s;
  }
  
  .file-upload-item:hover {
    background-color: #f9f9f9;
  }
  
  .file-upload-item.complete {
    background-color: #f0fff4;
  }
  
  .file-upload-item.error {
    background-color: #fff0f0;
  }
  
  .file-icon {
    font-size: 20px;
    margin-right: 12px;
    width: 24px;
    text-align: center;
  }
  
  .file-info {
    flex: 1;
    min-width: 0;
  }
  
  .file-name {
    font-weight: 500;
    font-size: 13px;
    color: #333;
    margin-bottom: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .file-details {
    display: flex;
    align-items: center;
    font-size: 11px;
    color: #777;
    margin-bottom: 6px;
  }
  
  .file-size {
    margin-right: 8px;
  }
  
  .status-text {
    color: #777;
  }
  
  .status-text.success {
    color: #2ecc71;
  }
  
  .status-text.error {
    color: #e74c3c;
  }
  
  .progress-bar-container {
    height: 4px;
    background-color: #f0f0f0;
    border-radius: 2px;
    overflow: hidden;
  }
  
  .progress-bar {
    height: 100%;
    background-color: #3498db;
    transition: width 0.3s ease;
  }
  
  .progress-bar.complete {
    background-color: #2ecc71;
  }
  
  .progress-bar.error {
    background-color: #e74c3c;
  }
  
  .upload-status-icon {
    margin-left: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
  }
  
  .spinner {
    animation: spin 1.5s linear infinite;
    color: #3498db;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .success-icon {
    color: #2ecc71;
  }
  
  .error-icon {
    color: #e74c3c;
  }
  
  .upload-summary {
    padding: 8px 15px;
    background-color: #f8f9fa;
    border-top: 1px solid #e0e0e0;
    font-size: 12px;
    color: #666;
    text-align: center;
  }

  
  
  /* Responsive adjustments */
  @media (max-width: 480px) {
    .file-upload-status-container {
      width: calc(100% - 20px);
      bottom: 10px;
      right: 10px;
    }
  }
